<template>
  <div>
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-row class="bradcrumb">
      <router-link :to="{ name: 'management-dashboard' }" class="root">
        Dashboard
      </router-link>
      <span class="child">/ Publisher </span> </v-row
    ><br />
    <section class="bg-color-">
      <div class="container">
        <template>
          <v-card>
            <v-card-title>
              <v-autocomplete
                persistent-hint
                label="Select Orgnaization"
                :items="org_list"
                item-text="orgName"
                item-value="orgid"
                v-model="orgid"
                style="margin-top:10px;"
                @change="getdetails()"
              ></v-autocomplete>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <template>
                <download-excel
                  :data="details_list"
                  :fields="single_"
                  worksheet="Publisher Data"
                  name="Publisher Data.xls"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="green"
                        size="40"
                        left
                        v-bind="attrs"
                        v-on="on"
                        style="margin-left:20px;"
                        >mdi-file-excel</v-icon
                      >
                    </template>
                    <span>Import to Excel</span>
                  </v-tooltip>
                </download-excel>
              </template>
            </v-card-title>
            <template>
                  <div class="text-center" v-if="circular">
                    <v-progress-linear
                      indeterminate
                      height="25"
                      color="#393e46"
                    >
                      <strong style="color:#FFF;">Loading...</strong>
                    </v-progress-linear>
                  </div>
                </template>
            <v-data-table
              :headers="headers"
              :items="details_list"
              :search="search"
            >
            </v-data-table>
          </v-card>
        </template>
      </div>
    </section>
    <!-- /. section -->
  </div>
  <!-- /. root div -->
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data: () => ({
    circular:false,
    org_list: [],
    details_list: [],
    snackbar_msg: "",
    color: "",
    snackbar: false,
    search: "",
    orgid:null,
    headers: [
      { text: "Sr. No.", value: "srno" },
      { text: "Publisher Name", value: "pub_name" },
    ],
    single_: {
      "Sr. No.": "srno",
     "Publisher": "pub_name",
    },
  }),
  mounted() {
    this.onLoad();
  },
  methods: {
    onLoad() {
      this.circular=true
      axios
        .post("/Management/getorglist")
        .then((res) => {
          if (res.data.msg == "200") {
            this.org_list = res.data.orglist;
          
            this.circular=false
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
    },
    getdetails() {
      if(this.orgid=='ALL')
      {

      this.circular=true
      axios
        .post("/Management/getAllPubData")
        .then((res) => {
          if (res.data.msg == "200") {
            this.details_list = res.data.pubdatalist;
            this.circular=false
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
      }
      
      else{
const data = {
        orgid: this.orgid,
      };
      this.circular=true
      axios
        .post("/Management/getPubDetails", data)
        .then((res) => {
          if (res.data.msg == "200") {
            this.details_list = res.data.pubdatalist;
            this.circular=false
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          window.console.log(error);
        });
      }
      
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
  },
  watch: {},
};
</script>
<style scoped>
@media (min-width: 320px) and (max-width: 767.98px) {
}
@media (min-width: 768px) and (max-width: 991.98px) {
}
@media (min-width: 992px) and (max-width: 1024px) {
}
</style>
